import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../../contexts/auth';
import { DesocupacaoImoveis } from '../../../../interfaces/ISite';
import Relatorio from '../../../../components/common/Relatorio';

const RelatorioDesocupadosPage: React.FC = () => {
    const [ficha, setFicha] = useState<DesocupacaoImoveis[]>([]);
    const { buscaListaDesocupados } = useContext(AuthContext);
    const { exportaListaDesocupados } = useContext(AuthContext);

    return (
        <div>
            <Typography variant="h6">Relatório Desocupados</Typography>
            <Relatorio
                busca={buscaListaDesocupados}
                exporta={exportaListaDesocupados}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"Desocupados"}
            />
        </div>
    );
};

export default RelatorioDesocupadosPage;