import React from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css';
import { IClientErro } from '../interfaces/IRenovacaoErros';
interface TableDataProps {
  ficha: IClientErro[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}
const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  
  const fixDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };
  const handleDateTime = (date: string) => date ? (date.toString().substring(0, 10) === '0001-01-01' ? "" : fixDateTime(date.toString())) : "";
  
  return (
    <div className="responsive-table-container">
      {loading ? <Loading /> : null}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CodCliente</TableCell>
              <TableCell>Nome do Cliente</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mensagem de Erro</TableCell>
              <TableCell>Data de Envio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ficha.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.CodPessoa}</TableCell>
                <TableCell>{item.ClientName}</TableCell>
                <TableCell>{item.ClientEmail}</TableCell>
                <TableCell>{item.ContractType}</TableCell>
                <TableCell>{handleDateTime(item.DateAdd)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TableData;