import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";

export const api = axios.create({
    baseURL: baseURL
});

export const createSession = async (email, password) => {
    return api.post("/api/Login/login", { email, password });
}

export const enviarRecuperacaoSenha = async (email) => {
    return api.post("/api/Login/RecuperarSenha", { email });
}

export const redefinirSenha = async (password, confirmPassword) => {
    let token = localStorage.getItem("token-redefinir-senha");

    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.post("/api/Login/RedefinirSenha", { password, confirmPassword });
}

export const validarToken = () => {
    let token = localStorage.getItem("token");

    if (token == null) {
        return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    api.get('/api/Login/validartoken')
        .then((resposta) => {
            console.log('Valido');
        })
        .catch((erro) => {
            console.error(erro);
            console.log("logout");
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("permission");
            api.defaults.headers.Authorization = null;
            window.location.href = '/login';
        });
}

export const enviarSMS = async (mensagem, telefones) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.post("/api/Common/EnviaSMS", { mensagem, telefones });
}

export const permissaoUsuarioAPI = async () => {
    let token = localStorage.getItem("token");
    let id = JSON.parse(localStorage.getItem("user")).id;

    let uri = "/api/Login/permissaousuario?id=" + id;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.post(uri);
}

export const enviarWelcomeKit = async (condominios) => {
    let uri = "/api/WelcomeKit/EnviaWelcomeKit?codigosCondom=" + condominios;
    return api.get(uri);
}

export const importarEconomiaImobiliar = async (economia) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/EconomiaImobiliar/ImportarEconomia", economia);
}

export const getEconomiasByCondominio = async (condominio) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/EconomiaImobiliar/EconomiasCondominio/" + condominio);
}

export const getInquilinos = async (condominio) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/EconomiaImobiliar/InquilinosAluguel");
}

export const processarArquivoInadimplencia = async (formData) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/EconomiaImobiliar/ProcessarArquivoInadimplencia", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export const importarInadimplencia = async (economia) => {

    return api.post("/api/EconomiaImobiliar/EnviarFilaInadimplencia?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W", economia);
}

export const processarArquivoEconomiaImobiliar = async (formData) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/EconomiaImobiliar/ProcessarArquivo", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export const processarFichaFinanceira = async (formData) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/FichaFinanceira/ProcessarArquivo", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export const processarArquivoLoteAutomacao = async (tipo_arquivo, lista) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/LoteAutomacao/ProcessarArquivo?tipo_arquivo=" + tipo_arquivo, lista);
}

export const filtrarLoteAutomacao = async (lote) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/LoteAutomacao/Search", lote);
}

export const salvarFerias = async (ferias) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/FeriasColaborador/Salvar", ferias);
}

export const enviarWhats = async (idferias) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/FeriasColaborador/EnviarWhats/" + idferias + "?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W");
}

export const forcarSenha = async (idferias) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/FeriasColaborador/AlterarAcessos/" + idferias + "?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W");
}

export const atualizarSenhaFerias = async (idferias) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/FeriasColaborador/AtualizarSenhaFerias/" + idferias);
}

export const buscarFerias = async (filtro) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/FeriasColaborador/Search", filtro);
}

export const gerarBoletoFichaFinanceira = async (ficha) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/FichaFinanceira/GerarBoleto", ficha);
}

export const filtrarERemessa = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/ERemessa/Search", body);
}

export const filtrarBoletoInadimplencia = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/BoletoInadimplenteNS/Search", body);
}

export const filtrarDigifile = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/Digifile/Search", body);
}

export const filtrarCND = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/CND/Search", body);
}

export const exportarERemessa = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/ERemessa/Exportar", body);
}

export const exportarDigifile = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/Digifile/Exportar", body, { responseType: 'blob' });
}

export const exportarCND = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/CND/Exportar", body, { responseType: 'blob' });
}

export const exportarBoletoInadimplencia = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/BoletoInadimplenteNS/Exportar", body, { responseType: 'blob' });
}

export const validarAmbiente = async () => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/Common/StatusAmbiente");
}

export const cadastrarUsuario = async (usuario) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/UsuarioCadastro", usuario);
};

export const buscarUsuario = async (filtro) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/UsuarioCadastro/Listar", filtro);
};

export const buscarLiderTime = async () => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/UsuarioCadastro/ListarLiderTime");
};

export const alterarSenha = async (id, senhaAtual, novaSenha) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.put("/api/UsuarioCadastro/alterarsenha", { id, senhaAtual, novaSenha });
};

export const buscarListaIntegradorSite = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/Site/Integrador/Listar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};

export const exportarListaIntegradorSite = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/Site/Integrador/Gerar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const buscarListaCDL = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/CDLWS/Listar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};

export const exportarListaCDL = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/CDLWS/Gerar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const buscarListaLeadsSite = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/Site/Leads/Listar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};

export const exportarListaLeadsSite = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/Site/Leads/Gerar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const exportarRelatorioEmailAniversario = async (Filtros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/aniversario/relatorio/emails_exportar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const buscarRelatorioEmailAniversario = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/aniversario/relatorio/emails?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};
export const buscarListaDesocupacaoSite = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/ControleImoveisDesocupacao/Listar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};

export const exportarListaDesocupacaoSite = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/ControleImoveisDesocupacao/Gerar", {
        params: Filtros,
        responseType: 'blob'
    });
}
export const pegarPermissoes = async () => {
        let token = localStorage.getItem("token");

        api.defaults.headers.Authorization = `Bearer ${token}`;

        return api.get("/api/Permissoes/pegarPermissoes");
}

export const pegarPermissoesUsuarios = async (email) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/Permissoes/pegarPermissoesUsuario?email=" + email );
}

export const atualizarPermissoesUsuarios = async (email, selectedPermissions) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/Permissoes/atualizarPermissoesUsuario", { email, selectedPermissions});
};
export const pegarBairrosCalculadora = async () => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/BI/getBairros");
}
export const pegarTiposCalculadora = async () => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/BI/getTipos");
}

export const listaCondominios = async () => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/condominio/listar");
}

export const bloqueioInadimplentes = async (body) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/BloqueioInadimplentes/handlestatus", body);
}
export const calcularAluguel = async (tipo, bairro, andar, area, qbanheiro, qelevador, 
                                    qdormit, qsala, qsuite, qvaga, vcond, viptu, 
                                    churrasqueira_imo, sacada, aquecimentogas, arcond, areaserv, armariobanheiro, 
                                    armariocozinha, armarioquarto, boxbanheiro, closet, despensa, 
                                    hidromassagem, lavabo, mobiliado, solmanha, aguaindividual, 
                                    gascanalizado, portaria24h, academia, churrasqueira_cond, piscina, 
                                    playground, salaofesta, salaojogos) => {
                                        
let token = localStorage.getItem("token");

api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/BI/calcular", { tipo, bairro, andar, area, qbanheiro, qelevador, 
                                    qdormit, qsala, qsuite, qvaga, vcond, viptu, 
                                    churrasqueira_imo, sacada, aquecimentogas, arcond, areaserv, 
                                    armariobanheiro,armariocozinha, armarioquarto, boxbanheiro, closet, despensa,
                                    hidromassagem, lavabo, mobiliado, solmanha, aguaindividual, 
                                    gascanalizado, portaria24h, academia, churrasqueira_cond, piscina,
                                    playground, salaofesta, salaojogos});
}
export const verificarCodigoImobiliar = async (codigo) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/IntegracaoBetalabs/VerificaCodigo", {codigo});
};

export const cadastrarBetalabs = async (codigo, userId) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/IntegracaoBetalabs/Cadastrar", {codigo, userId});
};

export const enviarEmailBetalabs = async (codigo) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/IntegracaoBetalabs/EnviarEmail", {codigo});
};

export const buscarIntegracaoBetalabs = async (Filtros, TipoBusca, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/IntegracaoBetalabs/Buscar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros }, TipoBusca);
}

export const buscarNumeroSorteSeguros = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/numerosorte/buscar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};

export const exportarNumerosSorteSeguros = async (Filtros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/numerosorte/exportar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const buscarClientes = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/BuscaClientes/Buscar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};
export const atualizarInadimplente = async (Id, Ativo, Inadimplente, Codigo_pessoa, UserId) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.put("api/BuscaClientes/Atualizar", {Id, Ativo, Inadimplente,Codigo_pessoa, UserId});
};
export const exportarListaBuscaCliente = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/BuscaClientes/Gerar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const importarNumeroSorteSeguros = async (listaNumeros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("api/numerosorte/importar", JSON.stringify(listaNumeros), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
}

export const buscarAcessosGestor = async (Codigo_Pessoa, ID_Condominio) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("api/ProtocoloCorrespondencia/Search", {Codigo_Pessoa, ID_Condominio});
}

export const imprimirDadosCondominos = async (
        ID_Condominio, Titulo, Observacao, ID_Economia, 
        Bloco, Economia_Sindico, Somente_Sindico
    ) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`; 

    return api.post(
        "api/ProtocoloCorrespondencia/Report", 
        {ID_Condominio, Titulo, Observacao, ID_Economia, Bloco, Economia_Sindico, Somente_Sindico}, {responseType: 'blob'}
    );
}

export const buscarAniversariantesImobiliar = async () => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/aniversariante/imobiliar");
}

export const buscarAniversariantesConsultaIndividual = async (nomePessoa) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/aniversariante/consultaindividual", {
        params: {
            nomePessoa
        },
    });
}

export const buscarAniversariantesAgenciaV1 = async (mesAniversario, tipoCliente) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.get("api/aniversariante/agenciav1", {
        params: {
            mesAniversario,
            tipoCliente,
        },
    });
}

export const criarBloqueadorEmail = async (EmailCliente, Codimovel, TipoEmail) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.post("api/EmailBlocker", {
        clientEmail: EmailCliente,
        codimovel: Codimovel,
        emailType: TipoEmail
    });
}

export const deletarBloqueadorEmail = async (EmailCliente, TipoEmail, Codimovel) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.delete("api/EmailBlocker", {
        params: {
            clientEmail: EmailCliente,
            emailType: TipoEmail,
            codimovel: Codimovel
        }
    });
}

export const buscarBloqueadorEmail = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/EmailBlocker/Relatorio/Listar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
}

export const exportarBloqueadorEmail = async (Filtros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/EmailBlocker/Relatorio/Exportar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const buscarTiposEmail = async () => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/EmailBlocker/TiposEmail");
}

export const exportarRelatorioEmailOnboarding = async (Filtros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/onboarding/relatorio/Emails_exportar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const buscarRelatorioEmailOnboarding = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/onboarding/relatorio/emails?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
}

export const listarTemplatesEmailsOnboarding = async () => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/onboarding/emails/templates");
}

export const listarNomeGestoresOnboarding = async () => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/onboarding/gestores/nomes");
}

export const listarTiposEmailsOnboarding = async () => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/onboarding/emails/tipos");
}

export const buscarRelatorioEsteiraRenovacao = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    
    return api.get("api/renovacao/contratos/Relatorio/Listar?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
}

export const listarNomeGestoresEsteiraRenovacao = async () => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    
    return api.get("api/renovacao/contratos/Managers");
}

export const getNineBox = async (Filtros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    
    return api.post("api/umentor/search", {Filtros});
}

export const buscarErrosRelatorioEsteiraRenovacao = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    
    return api.get("api/renovacao/contratos/Relatorio/Listar-erros?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
}

export const exportarRelatorioEsteiraRenovacao = async (Filtros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/renovacao/contratos/relatorio/Exportar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const exportarRelatorioErrosEsteiraRenovacao = async (Filtros) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/renovacao/contratos/relatorio/exportar-erros", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const buscarListaDesocupados = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/ControleImoveisDesocupacao/BuscarDesocupadosPaginado?PageNumber=" + PaginaAtual + "&PageSize=" + ItensPagina, { params: Filtros });
};

export const exportarListaDesocupados = async (Filtros, PaginaAtual, ItensPagina) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/ControleImoveisDesocupacao/GerarDesocupados", {
        params: Filtros,
        responseType: 'blob'
    });
}

export const verificarEmailBloqueado = async (EmailCliente, Codimovel, TipoEmail) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.get("api/EmailBlocker/verificar-bloqueado?ClientEmail= " + EmailCliente + 
                                                            "&Codimovel= " + Codimovel + 
                                                            "&EmailType= " + TipoEmail
    );
}

export const buscarEmailBloqueadoPorTipo = async (EmailType) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/EmailBlocker/busca-por-tipo?EmailType=" + EmailType);
};