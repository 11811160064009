import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Typography, Select, MenuItem, FormControl, Box, InputLabel, Stack, Snackbar, Alert } from '@mui/material';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import { LoteAutomacao } from '../../interfaces/ILoteAutomacao';
import FileUpload, { FileUploadHandle } from '../../components/common/FileUpload';

const optionsTipoArquivo = [
    { value: '0', label: 'Comprovante Bancário' },
    { value: '1', label: 'Recibo Nota de Débito' },
    { value: '2', label: 'Taxas ADM Guarida' },
    { value: '3', label: 'VT Soluções' },
    { value: '4', label: 'Secovi' },
    { value: '5', label: 'UniProof' },
    { value: '6', label: 'SafeWeb' },
    { value: '7', label: 'ISSQN' },
    { value: '8', label: 'Arquivo Boletos ADM' },
];

const fileExtensions:{ [key: string]: string[] } = {
    '0': ['.pdf'], // Comprovante Bancário
    '1': ['.txt'], // Recibo Nota de Débito
    '2': ['.zip', '.pdf'], // Taxas ADM Guarida
    '3': ['.pdf'], // VT Soluções
    '4': ['.pdf'], // Secovi
    '5': ['.pdf'], // Uniproof
    '6': ['.zip'], // SafeWeb
    '7': ['.zip'], // ISSQN
    '8': ['.*'], // Arquivo Boletos ADM
};

const validateFileExtension = (fileName: string, allowedExtensions: string[]): boolean => {
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

    if (allowedExtensions.includes('.*')) {
        return true;
    }

    return allowedExtensions.includes(fileExtension);
};

interface ArquivosAutomacao {
    Arquivos: string[];
}

function base64ToBlob(base64:string, contentType = '') {
    try {
        // Decode base64
        const byteCharacters = atob(base64);
        const byteArrays = [];

        // Split into chunks to handle large files
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    } catch (error) {
        console.error('Error converting base64 to blob:', error);
        throw new Error('Failed to process file data');
    }
}

function downloadBlob(blob: Blob, fileName: string) {
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
}

const ProcessarArquivoPage = () => {
    const [files, setFiles] = useState<string[]>([]);
    const [tempFiles, setTempFiles] = useState<string[]>([]);
    const { processaArquivoLoteAutomacao } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [descricao, setDescricao] = useState('');
    const [loteAutomacao, setLoteAutomacao] = useState<LoteAutomacao>();
    const [selectedOptionTipoArquivo, setselectedOptionTipoArquivo] = useState('0');
    const [alert, setAlert] = useState<{ severity: 'success' | 'error', message: string } | null>(null);

    const fileUploadRef = useRef<FileUploadHandle>(null);

    useEffect(() => {
        if (tempFiles.length > 0) {
            setFiles(prevFiles => {
                const uniqueFiles = Array.from(new Set([...prevFiles, ...tempFiles]));
                return uniqueFiles;
            });
            setTempFiles([]);
        }
    }, [tempFiles]);

    const handleSubmission = async (e: any) => {
        console.log('Arquivos Atuais', files);

        // Valida o formato dos arquivos
        const allowedExtensions = fileExtensions[selectedOptionTipoArquivo];
        const invalidFiles = files.filter(file => !validateFileExtension(file, allowedExtensions));

        if (invalidFiles.length > 0) {
            setAlert({ severity: 'error', message: `Os seguintes arquivos possuem formato inválido: ${invalidFiles.join(', ')}. Extensões permitidas: ${allowedExtensions.join(', ')}` });
            return;
        }

        setOpen(true);

        const arquivosAutomacao: ArquivosAutomacao = {
            Arquivos: files.map(file => file)
        };

        setDescricao("Processando o arquivo, pode demorar até 5 minutos, por favor aguarde o retorno!!!");

        try {
            let retorno = await processaArquivoLoteAutomacao(selectedOptionTipoArquivo, arquivosAutomacao);

            if (selectedOptionTipoArquivo == "8"){
                //const blob = new Blob(retorno.fileContents);
                const blob = base64ToBlob(retorno.fileContents, retorno.contentType);
                downloadBlob(blob, "Arquivos_ADM.zip");
            } else {
                setLoteAutomacao(JSON.parse(retorno['mensagem']));
            }
            
            setAlert({ severity: 'success', message: 'Arquivo processado com sucesso!' });
            handleClearFiles();
        } catch (err: any) {
            setAlert({ severity: 'error', message: 'Erro ao processar o arquivo.' });
            console.log(err.response.data);
        } finally {
            setOpen(false);
        }
    };

    const renderDados = (): JSX.Element => (
        <>
            <br />
            <br />
            <Typography variant="body1" gutterBottom>
                Código Lote: <b>{loteAutomacao?.id}</b>
            </Typography>
        </>
    );

    const handleSelectChangeTipoArquivo = (event: any) => {
        setselectedOptionTipoArquivo(event.target.value);
    };

    const handleUploadComplete = useCallback((uploadedFiles: string[]) => {
        setTempFiles(uploadedFiles);
    }, []);

    const handleRemoveFile = useCallback((fileName: string) => {
        setFiles(prevFiles => prevFiles.filter(file => file !== fileName));
    }, []);

    const handleClearFiles = () => {
        fileUploadRef.current?.clearFiles();
        setFiles([]);
    };

    return (
        <>
            <Box sx={{ minWidth: 120, maxWidth: 900 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Tipo de Arquivo</InputLabel>
                    <Select
                        fullWidth
                        label="Tipo de Arquivo"
                        value={selectedOptionTipoArquivo}
                        onChange={handleSelectChangeTipoArquivo}
                    >
                        {optionsTipoArquivo.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <br />
                    <FileUpload ref={fileUploadRef} onUploadComplete={handleUploadComplete} onRemoveFile={handleRemoveFile}/>
                    <br />
                    <Stack spacing={2} direction="row">
                        <Button
                            fullWidth
                            sx={{ "margin-top": "1%", "max-width": "fit-content" }}
                            variant="contained"
                            endIcon={<UploadFileIcon />}
                            onClick={handleSubmission}
                        >
                            Processar Arquivo
                        </Button>
                        <Button
                            fullWidth
                            sx={{ "margin-top": "1%", "max-width": "fit-content" }}
                            variant="outlined"
                            onClick={handleClearFiles}
                        >
                            Limpar Arquivos
                        </Button>
                    </Stack>
                    {open ? <Loading descricao={descricao} /> : renderDados()}
                </FormControl>
            </Box>
            {alert && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => setAlert(null)}
                >
                    <Alert
                        onClose={() => setAlert(null)}
                        severity={alert.severity}
                        sx={{ width: '100%' }}
                    >
                        {alert.message}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default ProcessarArquivoPage;
