import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { IClientContratoEmailsTipo } from "../interfaces/IRenovacaoContratos";
import { AuthContext } from "../../../contexts/auth";

interface IFilterFormOnboardingProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void;
    formData: any;
}

const FilterFormEsteiraRenovacao: React.FC<IFilterFormOnboardingProps> = ({ handleChange, handleChangeDate, handleSelectChange, formData }) => {
    const { listaNomeGestoresEsteiraRenovacao } = useContext(AuthContext);
    const [isEmailOpened, setIsEmailOpened] = useState<boolean>(false);
    const [managersName, setManagersName] = useState<string[]>([]);

    useEffect(() => {
        const fetchTemplatesAndManagers = async () => {
            const managers = await listaNomeGestoresEsteiraRenovacao();
            if (managers) {
                setManagersName(managers);
            };
        };

        fetchTemplatesAndManagers();
    }, []);

    const actionOptions = [
        { value: "Deseja informações", label: "Deseja informações" },
        { value: "Deseja renovar", label: "Deseja renovar" },
        { value: "Não clicou", label: "Não clicou" },
    ];

    const tipoOptions = [
        { value: "renovacaoProprietario", label: "Proprietários" },
        { value: "renovacaoLocatario", label: "Locatários" },
    ];

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setIsEmailOpened(checked); // Atualiza o estado do checkbox

        handleChange({
            target: {
                name,
                value: checked.toString(),
            },
        } as React.ChangeEvent<HTMLInputElement>);
    };

    const defaultDataDe = formData.dataInicial ? formData.dataInicial.toISOString().substring(0, 10) : "";
    const defaultDataAte = formData.dataFinal ? formData.dataFinal.toISOString().substring(0, 10) : "";
    const campoObrigatorio = (formData.dataInicial && formData.dataFinal);

    const defaultCodigoImovel = formData.CodImovel || "";

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Código imóvel"
                                type="text"
                                name="CodImovel"
                                value={defaultCodigoImovel}
                                onChange={handleChange}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="tipo-label">Tipo</InputLabel>
                            <Select
                                labelId="tipo-label"
                                label="Type"
                                name="Type"
                                value={formData["Type"]}
                                onChange={handleSelectChange}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Limpar</em>
                                </MenuItem>
                                {tipoOptions.map((tipo) => (
                                    <MenuItem key={tipo.value} value={tipo.value}>
                                        {tipo.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="acao-label">Ação</InputLabel>
                            <Select
                                labelId="acao-label"
                                label="Ação"
                                name="Action"
                                value={formData["Action"]}
                                onChange={handleSelectChange}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Limpar</em>
                                </MenuItem>
                                {actionOptions.map((action) => (
                                    <MenuItem key={action.value} value={action.value}>
                                        {action.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Campo Gestor */}
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="manager-label">Gestor</InputLabel>
                            <Select
                                labelId="manager-label"
                                label="Gestor"
                                name="ManagerName"
                                value={formData["ManagerName"]}
                                onChange={handleSelectChange}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Limpar</em>
                                </MenuItem>
                                {managersName.map((manager) => (
                                    <MenuItem key={manager} value={manager}>
                                        {manager}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Envio de:"
                                type="date"
                                name="dataInicial"
                                value={defaultDataDe}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Até:"
                                type="date"
                                name="dataFinal"
                                value={defaultDataAte}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FilterFormEsteiraRenovacao;
