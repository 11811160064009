import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { FichaFinanceira } from "../interfaces/IVendas";
import { IColaboradorNineBox } from "../interfaces/ICadastroUsuario";
import {
    api, createSession, enviarSMS, enviarWelcomeKit, permissaoUsuarioAPI, processarArquivoEconomiaImobiliar,
    importarEconomiaImobiliar, processarFichaFinanceira, gerarBoletoFichaFinanceira, validarAmbiente, filtrarERemessa,
    exportarERemessa, cadastrarUsuario, alterarSenha, processarArquivoInadimplencia, importarInadimplencia, getEconomiasByCondominio,
    buscarListaIntegradorSite, exportarListaIntegradorSite, filtrarDigifile, filtrarCND, exportarCND, exportarDigifile, buscarListaCDL, exportarListaCDL,
    buscarListaLeadsSite, exportarListaLeadsSite, pegarPermissoes, pegarPermissoesUsuarios, atualizarPermissoesUsuarios, pegarBairrosCalculadora, pegarTiposCalculadora, 
    calcularAluguel, enviarRecuperacaoSenha, redefinirSenha, filtrarBoletoInadimplencia, processarArquivoLoteAutomacao, filtrarLoteAutomacao, getInquilinos, 
    buscarUsuario, buscarLiderTime, exportarBoletoInadimplencia, salvarFerias, buscarFerias, enviarWhats, verificarCodigoImobiliar, cadastrarBetalabs, 
    enviarEmailBetalabs, buscarIntegracaoBetalabs, buscarRelatorioEmailAniversario, exportarRelatorioEmailAniversario, buscarNumeroSorteSeguros,
    importarNumeroSorteSeguros, exportarNumerosSorteSeguros, buscarAcessosGestor, imprimirDadosCondominos,buscarListaDesocupacaoSite, exportarListaDesocupacaoSite,
    atualizarSenhaFerias, forcarSenha, criarBloqueadorEmail, deletarBloqueadorEmail, buscarBloqueadorEmail, exportarBloqueadorEmail, buscarTiposEmail,
    buscarClientes, atualizarInadimplente, exportarListaBuscaCliente, buscarRelatorioEmailOnboarding, exportarRelatorioEmailOnboarding, listaCondominios, bloqueioInadimplentes,
    listarNomeGestoresOnboarding,
    listarTemplatesEmailsOnboarding,
    listarTiposEmailsOnboarding,
    exportarListaDesocupados,
    buscarListaDesocupados,
    buscarRelatorioEsteiraRenovacao,
    listarNomeGestoresEsteiraRenovacao,
    exportarRelatorioEsteiraRenovacao,
    buscarErrosRelatorioEsteiraRenovacao,
    exportarRelatorioErrosEsteiraRenovacao,
    getNineBox,
    buscarEmailBloqueadoPorTipo,
    verificarEmailBloqueado
} from "../services/api";
import { ICadastroUsuario } from "../interfaces/ICadastroUsuario";
import { IPaginacao } from "../interfaces/IPaginacao";
import { INumeroDaSorte } from "../interfaces/ISeguros";
import { ICondominioStatusHandler } from "../interfaces/ICondominio";

export const AuthContext = createContext<any>({});

export const AuthProvider = ({ children }: any) => {
    const navigate = useNavigate();

    const [user, setUser] = useState({ id: 0, email: "" });
    const [loading, setLoading] = useState(true);
    const [codPessoaBlockRenovacao, setCodPessoaBlockRenovacao] = useState(0);

    useEffect(() => {
        const recoveredUser = localStorage.getItem("user");

        if (recoveredUser) {
            setUser(JSON.parse(recoveredUser));
        }

        setLoading(false);
    }, []);

    const login = async (email: any, password: any) => {
        const response = await createSession(email, password);
        console.log("login auth", response.data);

        //Ir na API e criar Sessao
        const loggedUser = response.data.user;
        const token = response.data.token;

        localStorage.setItem("user", JSON.stringify(loggedUser));
        localStorage.setItem("token", token);
        permissaoUsuario();

        api.defaults.headers.Authorization = `Bearer ${token}`;

        setUser(loggedUser);

        await new Promise(f => setTimeout(f, 4000));

        navigate("/");
        //if (localStorage.getItem("permission") == null) { 
        window.location.reload();
        //}
    };

    const permissaoUsuario = async () => {
        const response = await permissaoUsuarioAPI();
        console.log("permissao", response.data);

        localStorage.setItem("permission", JSON.stringify(response.data));
    };

    const envioSMS = async (mensagem: string, telefones: string,) => {
        const response = await enviarSMS(mensagem, telefones);

        console.log("SMS ret: ", response.data);
    };

    const envioRecuperacaoSenha = async (email: string) => {
        const response = await enviarRecuperacaoSenha(email);
        console.log("Recuperacao de senha: ", response.data);
    };

    const redefinicaoSenha = async (password: string, confirmPassword: string) => {
        const response = await redefinirSenha(password, confirmPassword);
        console.log("Redefinir senha: ", response.data);
    }

    const envioWelcomeKit = async (condominios: string) => {
        const response = await enviarWelcomeKit(condominios);

        console.log("EnvioWelcomeKit: ", response.data);
    };

    const processaArquivoEconomiaImobiliar = async (file: FormData) => {
        const response = await processarArquivoEconomiaImobiliar(file);
        console.log("Processar Arquivo Economia Imobiliar: ", response.data);
        return response.data;
    };

    const processaFichaFinanceira = async (file: FormData) => {
        const response = await processarFichaFinanceira(file);
        console.log("Processar Ficha Financeira: ", response.data);
        return response.data;
    };

    const geraBoletoFichaFinanceira = async (ficha: FichaFinanceira) => {
        const response = await gerarBoletoFichaFinanceira(ficha);
        console.log("Gerar Boleto Ficha Financeira: ", response.data);
        return response.data;
    };

    const validaAmbiente = async () => {
        const response = await validarAmbiente();
        console.log("Ambiente: ", response.data);
        return response.data;
    };

    const filtraERemessa = async (body: any) => {
        const response = await filtrarERemessa(body);
        console.log("Filtro Eremessa: ", response.data);
        return response.data;
    };

    const filtraBoletoInadimplencia = async (body: any) => {
        const response = await filtrarBoletoInadimplencia(body);
        console.log("Filtro Boleto NS: ", response.data);
        return response.data;
    };

    const exportaBoletoInadimplencia = async (body: any) => {
        const response = await exportarBoletoInadimplencia(body);
        console.log("Exportar Boleto NS: ", response.data);
        return response.data;
    };

    const processaArquivoLoteAutomacao = async (tipo_arquivo: number, body: any) => {
        const response = await processarArquivoLoteAutomacao(tipo_arquivo, body);
        console.log("Arquivo Lote Automacao: ", response.data);
        return response.data;
    };

    const filtraLoteAutomacao = async (body: any) => {
        const response = await filtrarLoteAutomacao(body);
        console.log("Filtrar Lote Automacao: ", response.data);
        return response.data;
    };

    const salvaFerias = async (body: any) => {
        const response = await salvarFerias(body);
        console.log("Salvar Ferias: ", response.data);
        return response.data;
    };

    const enviaWhats = async (idFerias: number) => {
        const response = await enviarWhats(idFerias);
        console.log("Salvar Ferias: ", response.data);
        return response.data;
    };

    const forcaSenha = async (idFerias: number) => {
        const response = await forcarSenha(idFerias);
        console.log("Forçar Senha Ferias: ", response.data);
        return response.data;
    };

    const buscaFerias = async (body: any) => {
        const response = await buscarFerias(body);
        console.log("Buscar Ferias: ", response.data);
        return response.data;
    };

    const filtraDigifile = async (body: any) => {
        const response = await filtrarDigifile(body);
        console.log("Filtro Digifile: ", response.data);
        return response.data;
    };

    const filtraCND = async (body: any) => {
        const response = await filtrarCND(body);
        console.log("Filtro CND: ", response.data);
        return response.data;
    };

    const exportaERemessa = async (body: any) => {
        const response = await exportarERemessa(body);
        console.log("Exporta Eremessa: ", response.data);
        return response.data;
    };

    const exportaDigifile = async (body: any) => {
        const response = await exportarDigifile(body);
        console.log("Exporta Digifile: ", response.data);
        return response.data;
    };

    const exportaCND = async (body: any) => {
        const response = await exportarCND(body);
        console.log("Exporta CND: ", response.data);
        return response.data;
    };

    const processaArquivoInadimplencia = async (economia: any) => {
        const response = await processarArquivoInadimplencia(economia);
        console.log("Processar Arquivo Inadimplencia: ", response.data);
        return response.data;
    };

    const importaInadimplencia = async (economia: any) => {
        const response = await importarInadimplencia(economia);
        console.log("Processar Inadimplencia: ", response.data);
        return response.data;
    };

    const economiasByCondominio = async (codigo: string) => {
        const response = await getEconomiasByCondominio(codigo);
        console.log("Get Economias: ", response.data);
        return response.data;
    };

    const getAllInquilinos = async () => {
        const response = await getInquilinos();
        console.log("Get Inquilinos: ", response.data);
        return response.data;
    }; 

    const importaEconomiaImobiliar = async (economia: any) => {
        const response = await importarEconomiaImobiliar(economia);
        console.log("Processar Economia Imobiliar: ", response.data);
        return response.data;
    };

    const logout = () => {
        console.log("logout");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("permission");
        api.defaults.headers.Authorization = null;
        setUser({ id: 0, email: "" });
        navigate("/login");
    };

    const cadastraUsuario = async(usuario : ICadastroUsuario) => {
        const response = await cadastrarUsuario(usuario);

        console.log("Cadastro ret:", response.data);
    };

    const buscaUsuario = async(filtro : any) => {
        const response = await buscarUsuario(filtro);

        console.log("busca cadastro:", response.data);
        return response.data;
    };

    const buscaLiderTime = async() => {
        const response = await buscarLiderTime();

        console.log("busca lider:", response.data);
        return response.data;
    };

    const alteraSenha = async(id: number, senhaAtual: string, novaSenha: string) => {
        const response = await alterarSenha(id, senhaAtual, novaSenha);

        console.log("Alterar senha ret:", response.data);
    };
    const buscaListaIntegradorSite = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarListaIntegradorSite(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca Lista Integrador Site: ", response.data);
        return response.data;
    };
    const exportaListaIntegradorSite = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await exportarListaIntegradorSite(Filtros, PaginaAtual, ItensPagina);
        console.log("Exporta Lista Integrador Site: ", response.data);
        return response.data;
    };
    const buscaListaCDL = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarListaCDL(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca Lista CDL: ", response.data);
        return response.data;
    };
    const exportaListaCDL = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await exportarListaCDL(Filtros, PaginaAtual, ItensPagina);
        console.log("Exporta Lista CDL: ", response.data);
        return response.data;
    };
    const buscaListaLeadsSite = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarListaLeadsSite(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca Lista Leads Site: ", response.data);
        return response.data;
    };
    const exportaListaLeadsSite = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await exportarListaLeadsSite(Filtros, PaginaAtual, ItensPagina);
        console.log("Exporta Lista Leads Site: ", response.data);
        return response.data;
    };
    const exportaRelatorioEmailAniversario = async (Filtros: any) => {
        const response = await exportarRelatorioEmailAniversario(Filtros);
        return response.data;
    };
    const buscaRelatorioEmailAniversario = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {       
        const response = await buscarRelatorioEmailAniversario(Filtros, PaginaAtual, ItensPagina);
        return response.data;
    };
    const pegaPermissoes = async () => {
        const response = await pegarPermissoes();
        console.log("Permissões: ", response.data);
        return response.data;
    };
    const pegaPermissoesUsuarios = async (email: string) => {
        const response = await pegarPermissoesUsuarios(email);
        return response.data;
    };
    const atualizaPermissoesUsuarios = async (email: string, selectedPermissions: []) => {
        const response = await atualizarPermissoesUsuarios(email, selectedPermissions);
        return response.data;
    };
    const pegaBairros = async () => {
        const response = await pegarBairrosCalculadora();
        console.log("Bairros:", response.data);
        return response.data;
    }
    const pegaTipos = async () => {
        const response = await pegarTiposCalculadora();
        console.log("Tipos:", response.data);
        return response.data;
    }

    const pegaCondominios = async () => {
        const response = await listaCondominios();
        return response.data;
    }

    const postBloqueioInadimplentes = async (body: ICondominioStatusHandler) => {
        return await bloqueioInadimplentes(body);
    }

    const calculaAluguel = async (tipo: string, bairro: string, andar: number, area: number,
                                qbanheiro: number, qelevador: number, qdormit: number, qsala: number, qsuite: number,
                                qvaga: number, vcond: number, viptu: number, churrasqueira_imo: boolean, sacada: boolean,
                                aquecimentogas: boolean, arcond: boolean, areaserv: boolean, armariobanheiro: boolean,
                                armariocozinha: boolean, armarioquarto: boolean, boxbanheiro: boolean, closet: boolean,
                                despensa: boolean, hidromassagem: boolean, lavabo: boolean, mobiliado: boolean,
                                solmanha: boolean, aguaindividual: boolean, gascanalizado: boolean,
                                portaria24h: boolean, academia: boolean, churrasqueira_cond: boolean,
                                piscina: boolean, playground: boolean, salaofesta: boolean, salaojogos: boolean) => {

        const response = await calcularAluguel(tipo, bairro, andar, area, qbanheiro, qelevador,
                                            qdormit, qsala, qsuite, qvaga, vcond, viptu,
                                            churrasqueira_imo, sacada, aquecimentogas, arcond, areaserv, armariobanheiro,
                                            armariocozinha, armarioquarto, boxbanheiro, closet, despensa,
                                            hidromassagem, lavabo, mobiliado, solmanha, aguaindividual, gascanalizado,
                                            portaria24h, academia, churrasqueira_cond, piscina, playground,
                                            salaofesta, salaojogos);
        console.log("Cálculo ret:" , response.data);
        return response.data
    }
    
    const buscaClientes = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarClientes(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca Clientes: ", response.data);
        return response.data;
    }

    const atualizaInadimplente = async (Id: number, Ativo: boolean, Inadimplente: boolean, Codigo_pessoa: any, UserId: any) => {
        const response = await atualizarInadimplente(Id, Ativo, Inadimplente, Codigo_pessoa, UserId);
        console.log("Atualiza Inadimplente: ", response.data);
        return response.data;
    }

    const exportaListaBuscaClientes = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await exportarListaBuscaCliente (Filtros, PaginaAtual, ItensPagina);
        console.log("Exporta Lista Integrador Site: ", response.data);
        return response.data;
    };

    const verificaCodigoImobiliar = async (Codigo: string) => {
        const response = await verificarCodigoImobiliar(Codigo);
        return response.data;
    };

    const cadastraBetalabs = async (Codigo: string, UserId: number) => {
        const response = await cadastrarBetalabs(Codigo, UserId);
        return response.data;
    };

    const enviaEmailBetalabs = async (Codigo: string) => {
        const response = await enviarEmailBetalabs(Codigo);
        return response.data;
    };

    const buscaIntegracaoBetalabs = async (Filtros: any, TipoBusca: number, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarIntegracaoBetalabs(Filtros, TipoBusca, PaginaAtual, ItensPagina);
        console.log("Busca Integrações Betalabs: ", response.data);
        return response.data;
    }

    const buscarNumerosDaSorte = async (Filtros: any, PaginaAtual: any, ItensPagina: any): Promise<IPaginacao<INumeroDaSorte>> => {
        const response = await buscarNumeroSorteSeguros(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca numeros da sorte: ", response.data);
        return response.data as IPaginacao<INumeroDaSorte>;
    };

    const exportarNumerosDaSorte = async (Filtros: any) => {
        const response = await exportarNumerosSorteSeguros(Filtros);
        console.log("Exporta Lista numeros Da Sorte: ", response.data);
        return response.data;
    };

    const importarNumerosDaSorte = async (numerosDaSorte: INumeroDaSorte[]) => {
        const response = await importarNumeroSorteSeguros(numerosDaSorte);
        console.log("Importa Lista numeros Da Sorte: ", response);
        return response;
    };

    const buscaAcessosGestor = async (codpessoa: string, codcondom: number) => {
        const response = await buscarAcessosGestor(codpessoa, codcondom);
        console.log("Busca acessos gestores de carteira: ", response);
        return response;
    }

    const imprimeDadosCondominos = async (codcondom: number, titulo: string, observacao: string, economia: string, 
                                    bloco: string, economiaSindico: boolean, somenteSindico: boolean) => {
        const response = await imprimirDadosCondominos(codcondom, titulo, observacao, economia, bloco, economiaSindico, somenteSindico);
        console.log("Imprime dados condominos: ", response);
        return response;
    }

    const buscaListaDesocupacaoSite = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarListaDesocupacaoSite(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca Lista Desocupacao Site: ", response.data);
        return response.data;
    };
    const exportaListaDesocupacaoSite = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await exportarListaDesocupacaoSite(Filtros, PaginaAtual, ItensPagina);
        console.log("Exporta Lista Desocupacao Site: ", response.data);
        return response.data;
    };

    const atualizaSenhaFerias = async (idFerias: number) => {
        const response = await atualizarSenhaFerias(idFerias);
        console.log("Atualizar Senha Ferias: ", response.data);
        return response.data;
    };

    const criaBloqueadorEmail = async (EmailCliente: any, TipoEmail: any, Codimovel: any) => {       
        const response = await criarBloqueadorEmail(EmailCliente, TipoEmail, Codimovel);
        return response.status;
    };

    const deletaBloqueioEmail = async (EmailCliente: any, TipoEmail: any, Codimovel: any) => {       
        const response = await deletarBloqueadorEmail(EmailCliente, TipoEmail, Codimovel);
        return response.status;
    };

    const buscaBloqueioEmail = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {       
        const response = await buscarBloqueadorEmail(Filtros, PaginaAtual, ItensPagina);
        return response.data;
    };

    const exportaBloqueioEmail = async (Filtros: any) => {
        const response = await exportarBloqueadorEmail(Filtros);
        return response.data;
    };

    const buscaTiposEmail = async () => {       
        const response = await buscarTiposEmail();
        return response.data;
    };

    const buscaRelatorioEmailOnboarding = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarRelatorioEmailOnboarding(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca Relatorio emails onboarding: ", response.data);
        return response.data;
    };

    const exportaRelatorioEmailOnboarding = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await exportarRelatorioEmailOnboarding(Filtros);
        console.log("Exporta Relatorio emails onboarding: ", response.data);
        return response.data;
    };

    const listaNomeGestoresOnboarding = async () => {
        const response = await listarNomeGestoresOnboarding();
        return response.data;
    };

    const listaTemplatesEmailsOnboarding = async () => {
        const response = await listarTemplatesEmailsOnboarding();
        return response.data;
    };

    const listaTiposEmailsOnboarding = async () => {
        const response = await listarTiposEmailsOnboarding();
        return response.data;
    };

    const buscaListaDesocupados = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarListaDesocupados(Filtros, PaginaAtual, ItensPagina);
        console.log("Busca Lista Desocupacao Site: ", response.data);
        return response.data;
    };

    const exportaListaDesocupados = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await exportarListaDesocupados(Filtros, PaginaAtual, ItensPagina);
        console.log("Exporta Lista Desocupacao Site: ", response.data);
        return response.data;
    };

    const buscaRelatorioEsteiraRenovacao = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarRelatorioEsteiraRenovacao(Filtros, PaginaAtual, ItensPagina);
        
        return response.data;
    };

    const listaNomeGestoresEsteiraRenovacao = async () => {
        const response = await listarNomeGestoresEsteiraRenovacao();
        
        return response.data;
    };

    const getNineBoxFiltro = async (Filtros: any): Promise<IColaboradorNineBox[]> => {
        const response = await getNineBox(Filtros);
        
        return response.data;
    };
    
    const exportaRelatorioEsteiraRenovacao = async (Filtros: any) => {
        const response = await exportarRelatorioEsteiraRenovacao(Filtros);
        
        return response.data;
    };
    

    const buscaErrosRelatorioEsteiraRenovacao = async (Filtros: any, PaginaAtual: any, ItensPagina: any) => {
        const response = await buscarErrosRelatorioEsteiraRenovacao(Filtros, PaginaAtual, ItensPagina);
        
        return response.data;
    };
    const exportaRelatorioErrosEsteiraRenovacao = async (Filtros: any) => {
        const response = await exportarRelatorioErrosEsteiraRenovacao(Filtros);
        console.log('gestores ', response);
        
        return response.data;
    };

    const verificaEmailBloqueado = async (EmailCliente: any, TipoEmail: any, Codimovel: any) => {       
        const response = await verificarEmailBloqueado(EmailCliente, Codimovel, TipoEmail);
        return response.data;
    };

    const buscaEmailBloqueadoPorTipo = async (EmailType: any) => {
        const response = await buscarEmailBloqueadoPorTipo(EmailType);
        
        return response.data;
    };

    return (
        <AuthContext.Provider value={{
            authenticated: user.id !== 0, user, loading, login, logout, envioWelcomeKit, buscaRelatorioEmailAniversario, pegaCondominios,
            envioSMS, processaArquivoEconomiaImobiliar, importaEconomiaImobiliar, processaFichaFinanceira,
            geraBoletoFichaFinanceira, validaAmbiente, filtraERemessa, exportaERemessa, cadastraUsuario, alteraSenha,
            processaArquivoInadimplencia, importaInadimplencia, economiasByCondominio, buscaListaIntegradorSite, exportaListaIntegradorSite,postBloqueioInadimplentes,
            filtraDigifile, filtraCND, exportaDigifile, exportaCND, buscaListaCDL, exportaListaCDL, buscaListaLeadsSite, exportaListaLeadsSite, 
            pegaPermissoes, pegaPermissoesUsuarios, atualizaPermissoesUsuarios, pegaBairros, pegaTipos, calculaAluguel, envioRecuperacaoSenha, 
            redefinicaoSenha, filtraBoletoInadimplencia, processaArquivoLoteAutomacao, filtraLoteAutomacao, getAllInquilinos, buscaUsuario, buscaLiderTime,
            exportaBoletoInadimplencia, salvaFerias, buscaFerias, enviaWhats, verificaCodigoImobiliar, cadastraBetalabs, enviaEmailBetalabs, buscaIntegracaoBetalabs,
            exportaRelatorioEmailAniversario, buscarNumerosDaSorte, exportarNumerosDaSorte, importarNumerosDaSorte, buscaAcessosGestor, imprimeDadosCondominos,
            buscaListaDesocupacaoSite, exportaListaDesocupacaoSite, atualizaSenhaFerias, forcaSenha, criaBloqueadorEmail, deletaBloqueioEmail, buscaBloqueioEmail, 
            exportaBloqueioEmail, buscaTiposEmail, buscaClientes, atualizaInadimplente, exportaListaBuscaClientes, buscaRelatorioEmailOnboarding, exportaRelatorioEmailOnboarding,
            listaNomeGestoresOnboarding, listaTemplatesEmailsOnboarding, listaTiposEmailsOnboarding, buscaRelatorioEsteiraRenovacao,listaNomeGestoresEsteiraRenovacao, exportaRelatorioEsteiraRenovacao,
            buscaErrosRelatorioEsteiraRenovacao, exportaRelatorioErrosEsteiraRenovacao, buscaListaDesocupados, exportaListaDesocupados, getNineBoxFiltro, codPessoaBlockRenovacao, setCodPessoaBlockRenovacao,
            verificaEmailBloqueado, buscaEmailBloqueadoPorTipo
        }}>
            {children}
        </AuthContext.Provider>
    );
}