import React, { useState } from 'react';
import { Alert, Button, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Loading from '../../../components/common/Loading';
import AdicionarEmailModal from './AdicionarEmailModal';
import { IBloqueioEmail } from '../interfaces/IBloqueioEmail';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveEmailModal from './RemoveEmailModal';

interface BloqueioEmailTableProps {
  ficha: IBloqueioEmail[];
  setFicha(ficha: any): void;
  loading: boolean;
  modalOpen: boolean;
  msgAlert: string;
  handleCloseModal: () => void;
}

const BloqueioEmailTable: React.FC<BloqueioEmailTableProps> = ({ ficha, setFicha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [removeModalText, setRemoveModalText] = useState('');
  const [selectedUser, setSelectedUser] = useState<IBloqueioEmail | null>(null);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<'success' | 'error'>('success');
  const [msg, setMsg] = useState('');

  const handleOpenAddEmail = () => {
    setAddModalOpen(true);
  };

  const handleCloseAddEmail = () => {
    setAddModalOpen(false);
    handleCloseModal();
  };

  const handleOpenRmvEmail = (user: IBloqueioEmail) => {
    let value = user.ClientEmail || user.Codimovel;
    setRemoveModalText(`Tem certeza de que deseja remover o registro ${value} para o tipo ${user.EmailType}?`);
    setSelectedUser(user);
    setRemoveModalOpen(true);
};

  const handleCloseRmvEmail = () => {
    setRemoveModalOpen(false);
    handleCloseModal();
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};

  return (
    <div className="responsive-table-container">
      {loading && <Loading />}
      <AdicionarEmailModal 
        ficha={ficha} 
        setFicha={setFicha} 
        isOpen={isAddModalOpen} 
        onClose={handleCloseAddEmail} 
        msgAlert={msgAlert} 
        setOpen={setOpen}
        setSeverity={setSeverity}
        setMsg={setMsg}/>
      <RemoveEmailModal 
        ficha={ficha} 
        setFicha={setFicha} 
        isOpen={isRemoveModalOpen} 
        onClose={handleCloseRmvEmail} 
        text={removeModalText} 
        user={selectedUser}
        setOpen={setOpen}
        setSeverity={setSeverity}
        setMsg={setMsg}/>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
      </Snackbar>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAddEmail}
        sx={{ mb: 2 }}
      >
        Adicionar Email
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Codimovel</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ficha.map((user: IBloqueioEmail, index) => (
              <TableRow key={index}>
                <TableCell>{user.ClientEmail}</TableCell>
                <TableCell>{user.Codimovel}</TableCell>
                <TableCell>{user.EmailType}</TableCell>
                <TableCell sx={{ textAlign: 'right' }} >
                  <Button
                    onClick={() => {handleOpenRmvEmail(user)}}
                    sx={{ backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred'} }}
                  >
                    <DeleteIcon sx={{ fontSize: 18 }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BloqueioEmailTable;
