import React, { useState, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import '../../../components/common/modal.css';
import { Button, CircularProgress } from '@mui/material';
import { AuthContext } from "../../../contexts/auth";
import { IBloqueioEmail } from '../../marketing/interfaces/IBloqueioEmail';
import { Modal as AntModal } from 'antd';
import styles from './styles/modal.module.css';

interface IEmailBlocker {
  clientEmail: string;
  codimovel: number;
  emailType: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  msgAlert: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSeverity: Dispatch<SetStateAction<'success' | 'error'>>;
  setMsg: Dispatch<SetStateAction<string>>;
  ficha: IBloqueioEmail[];
  setFicha(ficha: IBloqueioEmail[]): void;
}

const ListModal: React.FC<ModalProps> = ({ isOpen, onClose, msgAlert, setOpen, setSeverity, setMsg, ficha, setFicha }) => {
  const { buscaEmailBloqueadoPorTipo, deletaBloqueioEmail } = useContext(AuthContext);
  const [blockedClients, setBlockedClients] = useState<IEmailBlocker[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<IEmailBlocker | null>(null);
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchBlockedClients();
    }
  }, [isOpen]);

  const fetchBlockedClients = async () => {
    setLoading(true);
    try {
      const response = await buscaEmailBloqueadoPorTipo("%renova%");
      setBlockedClients(response);
    } catch (error) {
      setSeverity("error");
      setMsg("Erro ao buscar clientes bloqueados.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConfirm = (user: IEmailBlocker) => {
    setSelectedUser(user);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleChangeEmailTypeName = (emailType: string): string => {
    if (emailType.includes("locatario")) {
      return "Locatário";
    } else if (emailType.includes("proprietario")) {
      return "Proprietário";
    } else {
      return "Tipo desconhecido";
    }
  };

  const handleUnblock = async (user: IEmailBlocker | null = null) => {
    if (!user) return;
    try {
      const response = await deletaBloqueioEmail(user.clientEmail, user.emailType, user.codimovel);
      if (response === 200) {
        var userCode = user.clientEmail ? user.clientEmail : user.codimovel;
        setBlockedClients((prev) => 
          prev.filter((item) => item.clientEmail !== userCode && item.codimovel !== userCode)
        );
        handleCloseConfirm();
      }
    } catch (error) {
      console.error("Erro ao desbloquear cliente:", error);
    }
  };
  const handleCloseModal = () => {
    window.location.reload();
    onClose();
  }
  
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalBody}>
          {blockedClients.length > 0 ? (
            <table className={styles.modalTable}>
              <thead>
                <tr>
                  <th>Email / Código do Imóvel</th>
                  <th>Tipo de Email</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {blockedClients.map((client, index) => (
                  <tr key={index}>
                    <td>{client.clientEmail || client.codimovel}</td>
                    <td>{handleChangeEmailTypeName(client.emailType)}</td>
                    <td>
                      <Button
                        onClick={() => handleOpenConfirm(client)}
                        variant="contained" sx={{ backgroundColor: 'slategray'}}>
                        Desbloquear
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p></p>
          )}
        </div>
        <div className={styles.modalFooter}>
          <Button onClick={handleCloseModal} variant="contained" color="primary">
            Fechar
          </Button>
        </div>
      </div>
        <AntModal
        title="Confirmar Seleção"
        open={isConfirmOpen}
        onOk={() => handleUnblock(selectedUser)}
        onCancel={handleCloseConfirm}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>Você tem certeza que deseja desbloquear {selectedUser?.clientEmail ? selectedUser?.clientEmail : selectedUser?.codimovel} para o tipo {handleChangeEmailTypeName(selectedUser?.emailType || '')}?</p>
      </AntModal>
    </div>
  );
};
export default ListModal;
