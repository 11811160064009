import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, useContext, } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { AuthContext } from "../../../contexts/auth";
import "../../../styles/styles.css";
import { IClientErro } from "../interfaces/IRenovacaoErros";
export default function RelatorioEsteiraRenovacao() {
const {buscaErrosRelatorioEsteiraRenovacao, exportaRelatorioErrosEsteiraRenovacao } = useContext(AuthContext);
const [ficha, setFicha] = useState<IClientErro[]>([]);
    return (
        <>
            <Typography variant="h6">Relatório de Erros - Emails Esteira de Renovação</Typography>
            <Relatorio
                busca={buscaErrosRelatorioEsteiraRenovacao}
                exporta={exportaRelatorioErrosEsteiraRenovacao}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailErrosRenovacao"}
            />
            <Divider variant="middle" />
        </>
    );
}