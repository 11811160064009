import { FormControl, TextField } from "@mui/material";
import { FilterFormDesocupacaoProps } from "../RelatorioDesocupados/IDesocupados";

const FilterFormDesocupacao: React.FC<FilterFormDesocupacaoProps> = ({ handleChange, handleChangeDate, formData }) => {
    const defaultDataDe = formData.Data_Desocupado ?
        (formData.Data_Desocupado.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.Data_Desocupado.toISOString().substring(0, 10)
        ) : "";
    return (
        <>
            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Código Imobiliar"
                    name="CodImovel"
                    value={formData.CodImovel === "0" ? "" : formData.CodImovel}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Código Imoview"
                    name="Codigo_Imoview"
                    value={formData.Codigo_Imoview === "0" ? "" : formData.Codigo_Imoview}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>
            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',
                }}>

                <TextField
                    sx={{ mb: 2 }}
                    label="Data Desocupado:"
                    type="date"
                    name="Data_Desocupado"
                    value={defaultDataDe}
                    onChange={handleChangeDate}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </FormControl>

        </>
    );
};


export default FilterFormDesocupacao;