import { Box, Checkbox, FormControl, Grid, SelectChangeEvent, TextField } from "@mui/material";

interface IFilterFormErrosRenovacao {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void;
    formData: any;
}
const FilterFormErrosRenovacao: React.FC<IFilterFormErrosRenovacao> = ({ handleChangeDate, formData }) => {

    const defaultDataDe = formData.dataInicial ? formData.dataInicial.toISOString().substring(0, 10) : "";
    const defaultDataAte = formData.dataFinal ? formData.dataFinal.toISOString().substring(0, 10) : "";
    const campoObrigatorio = (formData.dataInicial && formData.dataFinal);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                    <TextField
                        label="Envio De:"
                        type="date"
                        name="dataInicial"
                        value={defaultDataDe}
                        onChange={handleChangeDate}
                        fullWidth
                        required={campoObrigatorio}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                    <TextField
                        label="Até:"
                        type="date"
                        name="dataFinal"
                        value={defaultDataAte}
                        onChange={handleChangeDate}
                        fullWidth
                        required={campoObrigatorio}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default FilterFormErrosRenovacao;
