import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, useContext, } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { AuthContext } from "../../../contexts/auth";
import "../../../styles/styles.css";
import { IClientContrato } from "../interfaces/IRenovacaoContratos";

export default function RelatorioEsteiraRenovacao() {
const {buscaRelatorioEsteiraRenovacao, exportaRelatorioEsteiraRenovacao } = useContext(AuthContext);
const [ficha, setFicha] = useState<IClientContrato[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Emails Esteira de Renovação</Typography>
            <Relatorio
                busca={buscaRelatorioEsteiraRenovacao}
                exporta={exportaRelatorioEsteiraRenovacao}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailEsteiraRenovacao"}
            />
            <Divider variant="middle" />
        </>
    );
}
