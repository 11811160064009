// src/components/MatrixNineBox.tsx

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './MatrixNineBox.css';
import { AuthContext } from "../../contexts/auth";
import { IColaboradorNineBox } from '../../interfaces/ICadastroUsuario';
import { Button, Typography, Select, MenuItem, FormControl, Box, InputLabel, Stack, Snackbar, SelectChangeEvent } from '@mui/material';

interface Collaborator {
  id: number;
  name: string;
  sector: string;
  photoUrl: string;
  boxNumber: number;
}

interface Box {
  title: string;
  collaborators: IColaboradorNineBox[];
}

const boxTitles: { [key: number]: string } = {
  1: '1 Insuficientes',
  2: '2 Trabalhar Valores',
  3: '3 Verificar Situação',
  4: '4 Desenvolver Comportamento',
  5: '5 Desenvolver Técnica',
  6: '6 Aprimorar Comportamento e Técnica',
  7: '7 Aprimorar Comportamento',
  8: '8 Aprimorar Técnica',
  9: '9 Destaques',
};

const optionsTiposDiretoria = [
  { value: '0', label: 'Adm/Financeiro' },
  { value: '1', label: 'P&C' },
  { value: '2', label: 'Condomínio' },
  { value: '3', label: 'Aluguel' },
  { value: '4', label: 'TI/Comunicação' },
  { value: '5', label: 'Seguros' },
];

const optionsArea = [
  { value: '0', label: 'Área 1' },
  { value: '1', label: 'Área 2' },
  { value: '2', label: 'Área 3444444444444444444444444444444' },
  // Adicione mais opções conforme necessário
];

const MatrixNineBox: React.FC = () => {
  const [boxes, setBoxes] = useState<{ [key: number]: Box }>({});
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const { getNineBoxFiltro } = useContext(AuthContext);
  const [selectedDiretoria, setSelectedDiretoria] = useState('');
  const [selectedArea, setSelectedArea] = useState('');

  const handleSelectChangeDiretoria = (event: SelectChangeEvent) => {
    setSelectedDiretoria(event.target.value);
  };
  
  const handleSelectChangeArea = (event: SelectChangeEvent) => {
    setSelectedArea(event.target.value);
  };

  function calculateBoxNum(comportamento: number, desempenho: number): number {
    // Mapeamento explícito baseado na sua numeração
    if (comportamento === 1 && desempenho === 1) return 1;
    if (comportamento === 1 && desempenho === 2) return 2;
    if (comportamento === 1 && desempenho === 3) return 4;
    if (comportamento === 2 && desempenho === 1) return 3;
    if (comportamento === 2 && desempenho === 2) return 6;
    if (comportamento === 2 && desempenho === 3) return 7; // Conforme seu exemplo
    if (comportamento === 3 && desempenho === 1) return 5;
    if (comportamento === 3 && desempenho === 2) return 8;
    if (comportamento === 3 && desempenho === 3) return 9;
    
    // Valor padrão caso não corresponda a nenhum mapeamento
    return 0;
  }

  useEffect(() => {
    /*axios.get<Collaborator[]>('/collaborators.json').then((response) => {
      const data = response.data;
      const groupedBoxes: { [key: number]: Box } = {};

      data.forEach((collaborator) => {
        const boxNum = collaborator.boxNumber;
        if (!groupedBoxes[boxNum]) {
          groupedBoxes[boxNum] = {
            title: boxTitles[boxNum],
            collaborators: [],
          };
        }
        groupedBoxes[boxNum].collaborators.push(collaborator);
      });*/
      async function fetchData() {
        let data = await getNineBoxFiltro("");
        const groupedBoxes: { [key: number]: Box } = {};

        // Inicializa `groupedBoxes` com todos os boxes
        for (let boxNum = 1; boxNum <= 9; boxNum++) {
          groupedBoxes[boxNum] = {
            title: boxTitles[boxNum],
            collaborators: [],
          };
        }

        data.forEach((collaborator: IColaboradorNineBox) => {
          console.log(collaborator);
          const comportamento = collaborator.comportamento;
          const desempenho = collaborator.desempenho;
          const boxNum = calculateBoxNum(comportamento, desempenho);
          console.log(boxNum);
          if (!groupedBoxes[boxNum]) {
            groupedBoxes[boxNum] = {
              title: boxTitles[boxNum],
              collaborators: [],
            };
          }
          groupedBoxes[boxNum].collaborators.push(collaborator);
          setBoxes(groupedBoxes);
        })
      }

      fetchData();
    
  }, []);

  // Adiciona ou remove a classe 'no-scroll' no body quando o popup é aberto ou fechado
  useEffect(() => {
    if (selectedBox !== null) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [selectedBox]);

  const renderBox = (boxNumber: number) => {
    const box = boxes[boxNumber];
    if (!box) return null;

    const collaboratorsToShow = box.collaborators.slice(0, 10);
    const hasMore = box.collaborators.length > 10;

    return (
      <div className={`box box-${boxNumber}`} key={boxNumber}>
        <h3>{box.title}</h3>
        <div className="photos">
          {collaboratorsToShow.map((collaborator) => (
            <div className="photo" key={collaborator.id}>
              <img
                src={collaborator.usuario.urL_Foto}
                alt={collaborator.usuario.username}
                title={collaborator.usuario.username}
              />
            </div>
          ))}
        </div>
        {hasMore && (
          <button onClick={() => setSelectedBox(boxNumber)} className="see-more">
            Ver mais
          </button>
        )}
      </div>
    );
  };

  const renderPopup = () => {
    if (selectedBox === null) return null;

    const box = boxes[selectedBox];

    const handleClose = () => {
      setSelectedBox(null);
    };

    return (
      <div className="popup" onClick={handleClose}>
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
          <h2>{box.title}</h2>
          <ul>
            {box.collaborators.map((collaborator) => (
              <li key={collaborator.id}>
                {collaborator.usuario.username} - {collaborator.usuario.departamento}
              </li>
            ))}
          </ul>
          <button onClick={handleClose}>Fechar</button>
        </div>
      </div>
    );
  };

  return (
    <div className="matrix-nine-box">
      {/* Selects alinhados lado a lado */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        {/* Diretoria Select */}
        <FormControl variant="outlined" sx={{ minWidth: 200, marginRight: 2 }}>
          <InputLabel id="diretoria-label">Diretoria</InputLabel>
          <Select
            labelId="diretoria-label"
            id="diretoria-select"
            label="Diretoria"
            value={selectedDiretoria}
            onChange={handleSelectChangeDiretoria}
          >
            {optionsTiposDiretoria.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Área Select */}
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="area-label">Área</InputLabel>
          <Select
            labelId="area-label"
            id="area-select"
            label="Área"
            value={selectedArea}
            onChange={handleSelectChangeArea}
          >
            {optionsArea.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Resto do componente */}
      {renderPopup()}
      <div className="matrix-container">
        {/* Texto da esquerda */}
        <div className="left-labels">
          <div>Acima das Expectativas</div>
          <div>Dentro das Expectativas</div>
          <div>Abaixo das Expectativas</div>
        </div>
        {/* Matriz com os boxes */}
        <div className="matrix-grid">
          {[5, 8, 9, 3, 6, 7, 1, 2, 4].map((boxNumber) => renderBox(boxNumber))}
        </div>
        {/* Texto inferior */}
        <div className="bottom-labels">
          <div>Abaixo das Expectativas</div>
          <div>Dentro das Expectativas</div>
          <div>Acima das Expectativas</div>
        </div>
      </div>
    </div>
  );
};

export default MatrixNineBox;
