import React, { useState, useContext, useEffect, SetStateAction, Dispatch } from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Loading from '../../../components/common/Loading';
import Modal from './BlockUserModal';
import ListModal from './ListBlockedClientsModal';
import '../../../components/common/Relatorio.css';
import { IClientContrato } from '../interfaces/IRenovacaoContratos';
import { AuthContext } from '../../../contexts/auth';
import { Modal as AntModal } from 'antd';

interface TableDataProps {
  ficha: IClientContrato[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  const [isBlockModalOpen, setBlockModalOpen] = useState(false);
  const [isListModalOpen, setListModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IClientContrato | null>(null);
  const [blockedItems, setBlockedItems] = useState<{ [key: string]: boolean }>({});
  const {verificaEmailBloqueado, deletaBloqueioEmail, codPessoaBlockRenovacao } = useContext(AuthContext);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  
  useEffect(() => {
    ficha.forEach(item => {
      checkBlockStatus(item);
    });
  }, [ficha]);

  useEffect(() => {
    setBlockedItems(prev => ({ ...prev, [codPessoaBlockRenovacao]: true }));
  }, [codPessoaBlockRenovacao]);

  const fixDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  const handleDateTime = (date: string) => date ? (date.toString().substring(0, 10) === '0001-01-01' ? "" : fixDateTime(date.toString())) : "";

  const handleOpenBlock = (user: IClientContrato | null = null) => {
    setSelectedUser(user);
    setBlockModalOpen(true);
  };

  const handleOpenList = () => {
    setListModalOpen(true);
  };

  const handleOpenConfirm = (user: IClientContrato) => {
    setSelectedUser(user);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleCloseBlockModal = () => {
    setBlockModalOpen(false);
  };


  const handleCloseListModal = () => {
    setListModalOpen(false);
  };

  const checkBlockStatus = async (user: IClientContrato) => {
    try {
      const response = await verificaEmailBloqueado(user.ClientEmail, user.ContractType, user.CodImovel);
      const isBlocked = await response;
      if (isBlocked) {
        setBlockedItems(prev => ({ ...prev, [user.CodPessoa]: isBlocked }));
      }
    } catch (error) {
      console.error("Erro ao bloquear cliente:", error);
    }
  };
  
  const handleUnblock = async (user: IClientContrato | null = null) => {
    if (!user) return;
    try {
      const response = await deletaBloqueioEmail(user.ClientEmail, user.ContractType, user.CodImovel);
      if (response === 200) {
          setBlockedItems(prev => {
            const updatedBlockedItems = { ...prev };
            delete updatedBlockedItems[user.CodPessoa];
            return updatedBlockedItems;
          });
        handleCloseConfirm();
      }
    } catch (error) {
      console.error("Erro ao desbloquear cliente:", error);
    }
};

const getContractStatusText = (contractType: string): string => {
  switch (contractType) {
    case 'renovacaoLocatario':
        return 'Locatário não clicou'
    case 'renovarLocatario':
      return 'Locatário deseja renovar';
    case 'infoLocatario':
      return 'Locatário deseja informações';
    case 'renovacaoProprietario':
      return 'Proprietário não clicou';
    case 'renovarProprietario':
      return 'Proprietário  deseja renovar';
    case 'infoProprietario':
      return 'Proprietário deseja informações';
    default:
      return contractType;
  }
};

  return (
    <div style={{ zoom: 0.9 }} className="responsive-table-container">
      {loading ? <Loading /> : null}
      <Button
        color="error" sx={{ margin: '10px', width: '20%', height: 50 }} variant="contained"
        onClick={() => handleOpenBlock(null)}
      >
        Adicionar Bloqueio Cliente
      </Button>
      <Button color="primary" sx={{ margin: '10px', width: '20%', height: 50 }} variant="contained"
        onClick={() => handleOpenList()}
      >
        Listar Clientes Bloqueados
      </Button>
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CodCliente</TableCell>
              <TableCell>Nome do Cliente</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>CodImovel</TableCell>
              <TableCell>Endereço do Imóvel</TableCell>
              <TableCell>Nome do Gestor</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Data de Vigência</TableCell>
              <TableCell>Último Envio</TableCell>
              <TableCell>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ficha.map((item: IClientContrato, index) => (
              <TableRow key={index}>
                <TableCell>{item.CodPessoa}</TableCell>
                <TableCell>{item.ClientName}</TableCell>
                <TableCell>{item.ClientEmail}</TableCell>
                <TableCell>{item.CodImovel}</TableCell>
                <TableCell>{item.PropertyAddress}</TableCell>
                <TableCell>{item.ManagerName || "N/A"}</TableCell>
                <TableCell>{getContractStatusText(item.ContractType)}</TableCell>
                <TableCell>{handleDateTime(item.DataVigF)}</TableCell>
                <TableCell>{handleDateTime(item.DateAdd)}</TableCell>
                <TableCell>
                  {blockedItems[item.CodPessoa] ? (
                    <Button
                    onClick={() => handleOpenConfirm(item)}
                    sx={{ backgroundColor: 'slategray', color: 'white', '&:hover': { backgroundColor: 'darkgray'},
                          margin: '10px', width: '90%', height: 40}}
                  >
                    Desbloquear
                  </Button>
                  ) : (
                    <Button
                    color='error'
                    onClick={() => handleOpenBlock(item)}
                    sx={{margin: '10px', width: '90%', height: 40}} variant="contained"
                  >
                    Bloquear
                  </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isBlockModalOpen && (
        <Modal
            isOpen={isBlockModalOpen}
            onClose={handleCloseBlockModal}
            email={selectedUser?.ClientEmail || ''}
            codimovel={selectedUser?.CodImovel || null}
            emailType={selectedUser?.ContractType || ''}
            codPessoa={selectedUser?.CodPessoa || 0}
            msgAlert={msgAlert}
            setOpen={() => setBlockModalOpen(false)}
            setSeverity={() => {}}
            setMsg={() => {}}
            ficha={[]}
            setFicha={() => {}}
        />
    )}
    {isListModalOpen && (
        <ListModal
            isOpen={isListModalOpen}
            onClose={handleCloseListModal}
            msgAlert={msgAlert}
            setOpen={() => setBlockModalOpen(false)}
            setSeverity={() => {}}
            setMsg={() => {}}
            ficha={[]}
            setFicha={() => {}}
        />
    )}
    <AntModal
      title="Confirmar Seleção"
      open={isConfirmOpen}
      onOk={() => handleUnblock(selectedUser)}
      onCancel={handleCloseConfirm}
      okText="Confirmar"
      cancelText="Cancelar"
    >
      <p>Você tem certeza que deseja desbloquear {selectedUser?.ClientName}?</p>
    </AntModal>
    </div>
  );
};
export default TableData;
